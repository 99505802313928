/* eslint-disable */
import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import {
  PageNav,
} from 'layouts';
import {
  Banner,
  Breadcrumbs,
} from 'components';
import { PAGES, BASE_URL } from 'config';

const BREADCRUMBS = [
  {
    name: 'About Us',
    absolute_slug: PAGES.ABOUT_BRAND.PATH,
  },
  {
    name: 'Affiliate',
    absolute_slug: 'about-us/affiliate-programme',
  },
];

const AffiliateProgramme = () => (
  <Layout page="about/affiliate-programme">

    <Helmet
      title="Haka Tours Affiliate Programme"
      meta={[
        {
          name: 'description', content: 'Start promoting our award-winning tours! Whether you’re a travel blogger or have a fully-fledged website, check out our professional affiliate programme to promote New Zealand tours.',
        },
        { name: 'og:title', content: 'Haka Tours Affiliate Programme' },
        {
          name: 'og:description', content: 'Start promoting our award-winning tours! Whether you’re a travel blogger or have a fully-fledged website, check out our professional affiliate programme to promote New Zealand tours.',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/affiliate-programme/` }]}
    />

    <Banner backgroundImage="https://static-hakatours.imgix.net/nz_affiliate_programme_haka_tours.jpg?fit=crop&auto=format">
      <h2 className="c-heading c-heading--h0">Join our Affiliate Programme</h2>
    </Banner>
    <PageNav pages={[PAGES.ABOUT_TRIPS, PAGES.ABOUT_STORY, PAGES.ABOUT_BRAND, PAGES.ABOUT_PEOPLE, PAGES.ABOUT_AWARDS, PAGES.ABOUT_FAQS, PAGES.ABOUT_REVIEWS]} />
    <section className="l-section">
      <div className="l-container">
        <Breadcrumbs list={BREADCRUMBS} />
      </div>
      <div className="l-container l-container--small u-text--center">
        <h2 className="c-heading c-heading--h2">Join our Affiliate Programme</h2>
      </div>
      <br />
      <div className="l-container u-text--center">
        Haka Tours is the leading provider of adventure and snow tours in New Zealand, and weaves equal measures of culture and adventure into all our trips.
        Whether you’re a blogger or have a fully-fledged website, our professional affiliate programme is ready and waiting for you to start promoting our award-winning tours!
      </div>
      <br />
      <div className="u-text--center">
        <a className="c-button c-button--border c-button--small" target="_blank" href="https://dashboard.commissionfactory.com/Affiliate/Register/64299/" style={{ backgroundColor: '#e01356', color: '#FFF' }}>
          Sign up
        </a>
      </div>
      <div className="l-container l-tour-included l-tour-page__section" style={{ border: 'none'}}>
        <h2 className="c-heading c-heading--h3">Why promote Haka Tours?</h2>
        <div>
          <ul style={{ columnCount: 1 }}>
            <li>Base commission of 7.5%</li>
            <li>Commission rate will be increased to 10% if 3 or more tours are sold in the last 30 days</li>
            <li>90 day cookie period</li>
            <li>Products to suit all ages and styles of travel</li>
            <li>Base range of 16 digital banners</li>
            <li>Available for world-wide travellers</li>
            <li>Mobile optimised site</li>
          </ul>
        </div>
      </div>
      <div className="l-container">
        We are ranked #1 in NZ, and as a locally owned and operated tour company we know our country like the back of our hand.
        Our knowledge as to the best routes and our assurance of quality with our own network of accommodation, allows us to provide the ultimate New Zealand experience. 
        And don’t just take it from us, our array of awards and amazing guests’ feedback speak for themselves.
      </div>
      <br />
      <div className="l-container">
        If you have any questions, do not hesitate to get in touch with the Haka Marketing Team at <a href="mailto:info@hakatours.com">info@hakatours.com</a> or +64 9 520 5593.
      </div>
    </section>
  </Layout>
);

export default AffiliateProgramme;
